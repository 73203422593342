"use client";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GlobalButtonFinal, ModalsWrapper, ModalWindow } from '../GlobalStyles';
import {useRouter} from "next/router";
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../../../src/messages';
import { getCookie, setCookie } from 'cookies-next';
import * as Sentry from '@sentry/nextjs';
export default function DialogShowVideo ({ isMessageReady, message,toggleShowVideo,endWithTutorial}) {

    const [alertShow, setShowVideoShow] = useState(false);
    const [alertTransitionShow, setShowVideoTransitionShow] = useState(false);
    const router = useRouter();


    const playerRef = useRef(null);

    // Funkce, která bude volána po načtení API
    const onYouTubeIframeAPIReady = () => {
        playerRef.current = new window.YT.Player('player', {
            height: '500',
            width: '100%',
            videoId: '9BNaZPh67-g', // nahraďte vaším ID videa
            events: {
                onReady: onPlayerReady,
            },
        });
    };

    const onPlayerReady = () => {
        // console.log('Přehrávač je připraven');
    };
    const [checkedView, setCheckedView] = useState(null);

    // Funkce, která zastaví a resetuje video
    const stopAndResetVideo = () => {
        console.log("END ", checkedView);

        if(endWithTutorial){
            setCookie('tutorialSeen',true);

            if(checkedView){

                fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"user/current", {
                    method: 'PUT',
                    body: JSON.stringify(    {
                        "dismissed_modals": {
                            "tutorialVideo": true
                        }
                    }),
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': "Bearer "+  getCookie('userTeam'), // notice the Bearer before your token
                    },
                })
                    .then(response => response.json())
                    .then(function(data){
                        if(data.message !== undefined){
                            Sentry.captureException("user/current"+": "+ data.message + " : " + getCookie("userMail"));
                        }
                        console.log("data tutorial", data);
                    });
            }
        }
        if (playerRef.current) {
            playerRef.current.stopVideo(); // zastaví přehrávání
            // případně pro reset pozice videa:
            // playerRef.current.seekTo(0, true);
        }
    };

    useEffect(() => {
        // Načtení YouTube IFrame API
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // Nastavení globální funkce, kterou API volá po načtení
        window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    }, []);


    const changeShowVideoShow = (variable) => {

        if(variable === true){

            setShowVideoShow(variable);
            setTimeout(function() {
                setShowVideoTransitionShow(true);
            },1);




        } else{


            setShowVideoTransitionShow(false);
            setTimeout(function() {
                setShowVideoShow(variable);
            },500);
            

        }

    };

    const closeVideo = () => {

        stopAndResetVideo();
        changeShowVideoShow(false);
    };


    const toggleShowVideoInside = useCallback((): void => {
        changeShowVideoShow(true);

        if (playerRef.current) {
            playerRef.current.playVideo();
        }

        // if(alertShow){
        //     changeShowVideoShow(false);
        // }else{
        //     changeShowVideoShow(true);
        // }


    }, []);
    useEffect(() => {

        toggleShowVideo && toggleShowVideo(toggleShowVideoInside);
    }, [toggleShowVideo, toggleShowVideoInside]);


    const closeModalOutsideClick = (e) => {

        if( e.target.getAttribute("data-closemodal") === "true"){
            changeShowVideoShow(false);
            stopAndResetVideo();
        }
    }

    const intl = useIntl();
    const [messageMain, setMessageMain] = useState(null);
    const handleCheckboxChange = (event) => {
        setCheckedView(event.target.checked);
    };

    useEffect(() => {
                if(message &&!isMessageReady){
                    setMessageMain( intl.formatMessage({ id: message }));
                } else {
                    setMessageMain(message);

                }

    }, [message]);

    return (
        <>
            <ModalsWrapper className={`alertCheck  ${alertTransitionShow ? 'showTransition' : 'hideTransition'}
             ${!alertShow ? 'hiddeComplete' : ''}`}
                           data-closemodal={true}
                           onClick={(e)=> closeModalOutsideClick(e)}
            >


                <ModalWindow
                    className={"center alert youtubevideo"}
                    id={'alertProductDialog'}
                    style={{ zIndex: "9999999999999999999"}}>

                    <div className={'modal-header'}>

                        <h4>Obalero tutorial</h4>
                    </div>


                    <div className={'modal-content shareProduct'} style={{ display: 'block', paddingTop: '30px' }}>
                        <div id="player"></div>


                        <div style={{float:"left",width:"200px", marginTop:"25px", position:"absolute"}}>

                            <input type={'checkbox'}  style={{float:"left"}}
                                   onChange={handleCheckboxChange} checked={checkedView}/>
                            <span  onClick={ ()=>setCheckedView(checkedView ? false : true)} style={{whiteSpace:"nowrap", float:"left", color:"#666",marginTop:"-3px", marginLeft:"5px", fontSize:"15px",textAlign:"left"}}>
                                   <FormattedMessage {...messages.dont_show_again}></FormattedMessage></span>
                        </div>

                        <GlobalButtonFinal className={'btn-circle borderBlue'} onClick={() => closeVideo()}
                                           style={{ marginTop: '15px' }}>
                            <FormattedMessage {...messages.products_modal_duplicate_button_close}></FormattedMessage>
                        </GlobalButtonFinal>

                    </div>
                </ModalWindow>


            </ModalsWrapper>


        </>
    )
}